import React, { useEffect, useCallback, useState } from "react"
import * as styles from "./list.module.css"
import Layout from "components/common/layout.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getBannerAsync } from "store/modules/bannerReducer.js"
import { getMonthListAsync } from "store/modules/postReducer.js"
import Pagenation from "components/tools/pagination.js"
import DisplayB from "components/tools/banner/displayB"
import Month from "components/post/month"
import Slide from "components/tools/banner/slide"
import queryString from "query-string"
const List = props => {
  const dispatch = useDispatch()
  const getMonthList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getMonthListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )
  const getBanner = useCallback(
    position => dispatch(getBannerAsync(position)),
    [dispatch]
  )

  const pagePostSize = 20

  const { monthList, monthTotal } = useSelector(
    ({ postReducer: { month } }) => month,
    shallowEqual
  )
  const { page } = queryString.parse(props.location.search)
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  //배너

  useEffect(() => {
    ;(async () => {
      await getMonthList(currentPage, pagePostSize)
      await getBanner("month")
    })()
  }, [currentPage])

  return (
    <Layout>
      <div className={styles.con}>
        <Slide />
        <div className={styles.main}>
          {monthList.map(post => (
            <Month post={post} key={post.book_no} />
          ))}
        </div>

        <Pagenation
          total={monthTotal}
          pagePostSize={pagePostSize}
          pageSize={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <DisplayB
          style={{
            width: "1050px",
            height: "142px",
            margin: "113px auto 30px auto",
          }}
        />
      </div>
    </Layout>
  )
}

export default List
